import React, { useState, useEffect } from "react";
import Header from "../header2/Header";
import { useNavigate, useLocation } from "react-router-dom";
import Seo from "../seo/Seo";

const Contact = ({ utmParams }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] = useState({
    to: "leads@grank.co.in",
    subject: "Enquiry For G-Rank",
    message: "",
    name: "",
    phone: "",
    city: "",
    business: "",
    company: "",
    url: "",
    cc: "suresh@grank.co.in, singam@grank.co.in",
    bcc: "server@grank.co.in",
    email: "", // Add email field to match the backend structure
  });

  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [loading, setLoading] = useState(false); // Track loading state

  const siteKey = "6LcuJ9QmAAAAAGeBZPkD4Am3FwfPo7fJoowZQpvI"; // reCAPTCHA site key

  useEffect(() => {
    // Update the URL and load reCAPTCHA script when the component mounts or location changes
    setFormData((prevData) => ({ ...prevData, url: window.location.href }));

    // Load reCAPTCHA script
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
    script.async = true;
    document.body.appendChild(script);
  }, [location, siteKey]);

  // Handle reCAPTCHA execution and form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true

    // Execute reCAPTCHA and get token
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(siteKey, { action: "submit" })
        .then(async (token) => {
          setRecaptchaToken(token);
          console.log("reCAPTCHA token:", token); // Print token to console

          const submissionData = { ...formData, token }; // Ensure recaptcha token is included

          try {
            const response = await fetch(
              "https://server.grank.co.in/send-email", // Ensure this is your correct backend URL
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(submissionData),
              }
            );

            if (response.ok) {
              const result = await response.json();
              console.log(result.message);
              localStorage.setItem("userMessage", formData.email); // Store email in local storage
              navigate("/thank-you");
            } else {
              const errorResult = await response.json();
              console.error("Error:", errorResult.error);
            }
          } catch (error) {
            console.error("Error:", error);
          } finally {
            setLoading(false); // Reset loading state
          }
        })
        .catch((error) => {
          console.error("reCAPTCHA error:", error);
          setLoading(false); // Reset loading state on error
        });
    });
  };

  // Handle input field changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Schema.org structured data
  const schema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Grank",
    image: "https://www.grank.co.in/assest/photos/g-rank-white-logo.png",
    "@id": "",
    url: "https://www.grank.co.in/contact",
    telephone: "8522953903",
    address: {
      "@type": "PostalAddress",
      streetAddress:
        "Urban Vault Citrine, 2nd Floor, No-43 New Airport Road, NH 7, Outer Ring Rd, Sahakar Nagar, Bengaluru, Karnataka, India - 560092",
      addressLocality: "Bangalore",
      postalCode: "560024",
      addressCountry: "IN",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 13.0418288,
      longitude: 77.5933501,
    },
    sameAs: [
      "https://www.facebook.com/Grank.India",
      "https://www.instagram.com/grank.india/",
      "https://twitter.com/Grank_India/",
      "https://www.linkedin.com/company/g-rank-digital-marketing-company/",
      "https://www.youtube.com/channel/UCAuK0EC-TmLLHVSmrjxMnIA",
    ],
  };

  return (
    <>
      <Seo
        title="Contact Us"
        description="Digital Marketing services in India. Explore how we effortlessly move your web domains to a more reliable, efficient platform."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="best domain transfer services in India"
        searchengines="ALL"
        canonical="https://www.grank.co.in/contact"
        schema={schema}
      />
      <section className="section-top">
        <Header />
        <section className="contact-us-section">
          <div className="container contact-us-contain">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-7 align-self-center">
                <img
                  className="img-fluid w-100 contact-img"
                  src="assest/photos/contact-us/contact-us2.png"
                  alt="G-Rank"
                />
              </div>
              <div className="col-12 col-md-12 col-lg-5">
                <div className="contact-us-form">
                  <h1>Get In Touch</h1>
                  <p>
                    Have an enquiry or some feedback for us?
                    <br />
                    Fill out the form below to contact our team.
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="contact-flex">
                      <input
                        className="floating-label"
                        placeholder="Name"
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="contact-flex">
                      <input
                        placeholder="Phone"
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="contact-flex">
                      <input
                        placeholder="Company Name"
                        type="text"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="contact-flex">
                      <select
                        name="business"
                        className="contact-select-div"
                        value={formData.business}
                        onChange={handleChange}
                        required
                      >
                        <option value="none">Type Of Business</option>
                        <option value="B2B">B2B</option>
                        <option value="B2C">B2C</option>
                      </select>
                    </div>
                    <div className="contact-flex">
                      <input
                        placeholder="City"
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="contact-flex">
                      <input
                        placeholder="Email"
                        type="email"
                        name="email" // Ensure email field is included
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="contact-flex">
                      <textarea
                        placeholder="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <h4>
                      <button type="submit" disabled={loading}>
                        {loading ? "Submitting..." : "Submit Now"}
                      </button>
                    </h4>
                  </form>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="contact-address">
                  <p>
                    Ground Floor, Queen's Plaza, 1-8-382, Passport Office Rd,
                    Patigadda, Begumpet, Hyderabad, Telangana - 500003
                  </p>
                  <h1>+91 8121826992</h1>
                  <h2>leads@grank.co.in</h2>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="contact-address">
                  <p>
                    Urban Vault Citrine, 2nd Floor, No-43 New Airport Road, NH
                    7, Outer Ring Rd, Sahakar Nagar, Bengaluru, Karnataka, India
                    - 560092
                  </p>
                  <h1>+91 9686442800</h1>
                  <h2>leads@grank.co.in</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Contact;
