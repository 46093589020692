import React from "react";
import Header from "../header2/Header";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
const OurCoreTeam = () => {
  return (
    <>
      <section className="section-top">
        <Header />

        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100"
            src="assest/photos/our-team/meet-our-team-banner-white1.png"
            alt="G-Rank"
          />

          <div className="meet-our-team-banner-div">
            <h2>Meet Our Team</h2>
            <h3>
              We are a team of dreamers, thinkers and doers. We are not just all
              work, We have fun and much more..!
            </h3>
            <Breadcrumbs
              className="breadcrumbs"
              aria-label="breadcrumb"
              style={{ padding: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#000",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>

              <Typography color="#000">Our Team</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}
        <section className="meet-our-core-team-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 ">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-8">
                    <div className="festive-img-div">
                      <img
                        className=""
                        src="assest/photos/gallery/3.jpg"
                        alt="G-Rank"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="col-12 festive-img-div2">
                      <img
                        className=""
                        src="assest/photos/gallery/festival-img7.jpeg"
                        alt="G-Rank"
                      />
                    </div>
                    <div className="col-12 festive-img-div2">
                      <img
                        className=""
                        src="assest/photos/gallery/festive-img2.jpeg"
                        alt="G-Rank"
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 festive-img-div3">
                    <img
                      className=""
                      src="assest/photos/gallery/gallery7.jpeg"
                      alt="G-Rank"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 festive-img-div4">
                    <img
                      className=""
                      src="assest/photos/gallery/2.jpg"
                      alt="G-Rank"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 align-self-center">
                <div className="meet-our-festive-moment">
                  <motion.h2
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    Festive Moments <br />
                    Captured in Colours
                  </motion.h2>
                  <motion.h3
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    At G-Rank, we Celebrate festivals and <br />
                    Immortalize each moment. We believe in making each moment
                    count.
                  </motion.h3>
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/our-gallery"
                  >
                    <span className="meet-our-team">
                      See More
                      <img
                        className="img-fluid"
                        src="assest/photos/our-team/right-arrow.png"
                        alt="G-Rank"
                      />
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="meet-our-core-team-section2">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-12 col-md-6 col-lg-6 align-self-center"
                id="order2"
              >
                <div className="meet-our-festive-moment2">
                  <motion.h2
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    Birthday Blim
                    <br />
                    Captured in Colours
                  </motion.h2>
                  <motion.h3
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    At G-Rank, birthday's call for massive celebrations.
                    Birthday bumps, cakes, Dance, Music's all of that to make
                    the day of our team eventful.
                  </motion.h3>
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/our-gallery"
                  >
                    <span className="meet-our-team2">
                      See More
                      <img
                        className="img-fluid"
                        src="assest/photos/our-team/right-arrow-white.png"
                        alt="G-Rank"
                      />
                    </span>
                  </NavLink>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6" id="order1">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-8 ">
                    <div className="festive-img-div">
                      <img
                        className=""
                        src="assest/photos/gallery/b3.jpg"
                        alt="G-Rank"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="col-12 festive-img-div2">
                      <img
                        className=""
                        src="assest/photos/gallery/b5.jpg"
                        alt="G-Rank"
                      />
                    </div>
                    <div className="col-12 festive-img-div2">
                      <img
                        className=""
                        src="assest/photos/gallery/birthday-img1.jpeg"
                        alt="G-Rank"
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-md-6 festive-img-div3">
                    <img
                      className=""
                      src="assest/photos/gallery/b1.jpg"
                      alt="G-Rank"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-md-6 festive-img-div4">
                    <img
                      className=""
                      src="assest/photos/gallery/b2.jpg"
                      alt="G-Rank"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="meet-our-core-team-section3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-8 ">
                    <div className="festive-img-div">
                      <img
                        className=""
                        src="assest/photos/gallery/f2.jpg"
                        alt="G-Rank"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="col-12 festive-img-div2">
                      <img
                        className=""
                        src="assest/photos/gallery/f1.jpg"
                        alt="G-Rank"
                      />
                    </div>
                    <div className="col-12 festive-img-div2">
                      <img
                        className=""
                        src="assest/photos/gallery/gallery12.jpeg"
                        alt="G-Rank"
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-md-6 festive-img-div3">
                    <img
                      className=""
                      src="assest/photos/gallery/gaming/1.jpg"
                      alt="G-Rank"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-md-6 festive-img-div4">
                    <img
                      className=""
                      src="assest/photos/gallery/game-img3.jpeg"
                      alt="G-Rank"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 align-self-center">
                <div className="meet-our-festive-moment">
                  <motion.h2
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    Gaming Adventures
                    <br />
                    Captured in Colour
                  </motion.h2>
                  <motion.h3
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    Every Month-end Saturday calls for fun games and frolic at
                    G-Rank. Our Anniversary and team outings mean never ending
                    joy and togetherness.
                  </motion.h3>
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/our-gallery"
                  >
                    <span className="meet-our-team">
                      See More
                      <img
                        className="img-fluid"
                        src="assest/photos/our-team/right-arrow.png"
                        alt="G-Rank"
                      />
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default OurCoreTeam;
