import React from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import Seo from "../seo/Seo";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { NavLink } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";

const BrandCommunication = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const schema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "G-Rank",
        item: "https://www.grank.co.in/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "branding agency",
        item: "https://www.grank.co.in/branding-agency",
      },
      {
        "@type": "ListItem",
        position: 3,
        name: "branding communication agency",
        item: "https://www.grank.co.in/branding-communication-agency",
      },
    ],
  };

  return (
    <>
      <Seo
        title="Branding Communication Agency In India - G-Rank"
        description="Transform your brand's communication with G-Rank, a branding and communication agency in India, committed to delivering innovative branding solutions."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="brand communication agency in bangalore,
        top brand communication agency in hyderbad,
        top brand communication agency in India"
        searchengines="ALL"
        canonical="https://www.grank.co.in/branding-communication-agency"
        schema={schema}
      ></Seo>

      <section className="section-top">
        <Header />
        <div className="branding-consulting-banner">
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/branding/brand-comminication-banner.png"
            alt="G-Rank"
            title="brand communication"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/branding/brand-comminication-banner-mobile.png"
            alt="G-Rank"
            title="brand communication"
          />
          <div className="branding-comminication-banner-new-div">
            <motion.h1
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              data-text="Brand Comminication agency"
            >
              {/* <span className="words3"></span> */}
              Branding
              <br />
              Communication Agency
            </motion.h1>
            <h2>Elevate Your Brand with us!</h2>

            <motion.h3
              href="#"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">Get Started</NavLink>
            </motion.h3>
            <Breadcrumbs
              className="breadcrumbs"
              aria-label="breadcrumb"
              style={{ float: "right", paddingTop: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#ccc",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#ccc",
                })}
                to="/branding-agency"
              >
                <p>Branding</p>
              </NavLink>

              <Typography color="text.primary">
                Branding Communication Agency
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="branding-process-div">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12 col-md-7 col-lg-6 ">
                <img
                  className="img-fluid branding-pro-img desktop-none"
                  src="assest/photos/branding/automation.png"
                  alt="G-Rank"
                  title="brand communication"
                />
                <div id="level">
                  <div id="content">
                    <div id="gears">
                      <div id="gears-static"></div>
                      <div id="gear-system-1">
                        <div className="shadow" id="shadow15"></div>
                        <div id="gear15"></div>
                        <div className="shadow" id="shadow14"></div>
                        <div id="gear14"></div>
                        <div className="shadow" id="shadow13"></div>
                        <div id="gear13"></div>
                      </div>
                      <div id="gear-system-2">
                        <div className="shadow" id="shadow10"></div>
                        <div id="gear10"></div>
                        <div className="shadow" id="shadow3"></div>
                        <div id="gear3"></div>
                      </div>
                      <div id="gear-system-3">
                        <div className="shadow" id="shadow9"></div>
                        <div id="gear9"></div>
                        <div className="shadow" id="shadow7"></div>
                        <div id="gear7"></div>
                      </div>
                      <div id="gear-system-4">
                        <div className="shadow" id="shadow6"></div>
                        <div id="gear6"></div>
                        <div id="gear4"></div>
                      </div>
                      <div id="gear-system-5">
                        <div className="shadow" id="shadow12"></div>
                        <div id="gear12"></div>
                        <div className="shadow" id="shadow11"></div>
                        <div id="gear11"></div>
                        <div className="shadow" id="shadow8"></div>
                        <div id="gear8"></div>
                      </div>
                      <div className="shadow" id="shadow1"></div>
                      <div id="gear1"></div>
                      <div id="gear-system-6">
                        <div className="shadow" id="shadow5"></div>
                        <div id="gear5"></div>
                        <div id="gear2"></div>
                      </div>
                      <div className="shadow" id="shadowweight"></div>
                      <div id="chain-circle"></div>
                      <div id="chain"></div>
                      <div id="weight"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-5 col-lg-6 branding-consulting-process-text">
                <motion.h4
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Our
                </motion.h4>
                <motion.h2
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Process
                </motion.h2>

                <motion.p
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  At G- Rank, we follow a comprehensive process to create
                  impactful website content. Our approach combines strategic
                  thinking, industry insights, and creative expertise to deliver
                  exceptional results. Our team believe in conducting in-depth
                  research and analysis of your brand, target audience, and
                  competition. This helps them to understand your unique value
                  proposition and identify key opportunities for online
                  visibility and engagement.
                </motion.p>
              </div>
            </div>
          </div>
        </div>
        {/* action black start*/}
        <div className="action-black-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch with a Branding and Communication Agency Today
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-black-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action black end*/}
        {/* what we do start */}
        <div className="container-fluid branding-consulting-what">
          <div className="row">
            <div className="col-12 col-md-7 col-lg-7" id="order2">
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                className=" branding-consulting-what-heading"
              >
                <h4>Why to Choose Our Brand Communication Services</h4>
              </motion.div>
              <div className="branding-consulting-what-text">
                <p>
                  <span>Clarity and Consistency :</span>
                  Your audience receives clear and consistent messages,
                  fostering trust and understanding of your brand offerings.
                </p>
                <p>
                  <span>Trust and Reliability :</span>
                  Transparent and honest communication builds trust with your
                  customers, leading to long-term relationships.
                </p>
                <p>
                  <span>Engagement and Interaction :</span>
                  Two-way communication encourages feedback and fosters a more
                  engaging brand experience.
                </p>
                <p>
                  <span>Support and Guidance :</span>
                  Clear communication provides the support and guidance your
                  customers need, whether through customer service, product
                  information, or updates.
                </p>
                <p>
                  <span>Relevance and Personalization :</span>
                  Tailored communication ensures you deliver relevant and
                  valuable information, enhancing the overall customer
                  experience.
                </p>
                <p>
                  <span>Updates and Innovation :</span>
                  Staying informed about product advancements and company
                  updates keeps your audience engaged and prevents them from
                  missing out on important information.
                </p>
                <p>
                  <span>Community and Connection :</span>
                  Effective communication can foster a sense of community and
                  belonging amongst your customer base.
                </p>
                <p>
                  <span>Audience Research :</span>
                  We gain a deep understanding of your target audience, their
                  needs, and preferred communication channels.
                </p>
                <p>
                  <span>Brand Strategy Development :</span>
                  We develop a comprehensive brand strategy that outlines your
                  messaging, tone of voice, and communication goals.
                </p>
                <p>
                  <span>Content Creation :</span>
                  Our team creates compelling and engaging content that
                  resonates with your audience and reinforces your brand
                  identity.
                </p>
                <p>
                  <span>Omnichannel Communication :</span>We develop a
                  communication plan that leverages multiple channels – social
                  media, email marketing, website content, and press releases –
                  to ensure consistent messaging across all touch points.
                </p>
                <p>
                  <span>Performance Measurement :</span>We track the
                  effectiveness of your communication efforts and make
                  adjustments as needed.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-5" id="order1">
              <motion.img
                initial={{ opacity: 0, scale: 0, y: 200 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="img-fluid branding-consulting-process-img"
                src="assest/photos/branding/what-we-do-img-blue4.png"
                alt="G-Rank"
                title="grank"
              />
            </div>
          </div>
        </div>
        {/* what we do end */}
        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div>
              <motion.h2
                className="branding-quick-link"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Quick Links
                <p className="d-block"> For You</p>
              </motion.h2>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        <img
          className="img-fluid w-100"
          src="assest/photos/branding/branding-footer-img.png"
          alt="G-Rank"
          title="grank"
        />
      </section>
    </>
  );
};

export default BrandCommunication;
