import React, { useState } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Seo from "../seo/Seo";
import { NavLink } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";
//import AddIcon from "@mui/icons-material/Add";
//import RemoveIcon from "@mui/icons-material/Remove";

const Branding = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  // useState section start--------------------------------------

  // acoordian button
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  //  useState section end----------------------------------------

  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const schema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "G-Rank",
        item: "https://www.grank.co.in/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Branding Agency",
        item: "https://www.grank.co.in/branding-agency",
      },
      {
        "@type": "ListItem",
        position: 3,
        name: "Brand Identity Agency",
        item: "https://www.grank.co.in/brand-identity-agency",
      },
    ],
  };

  return (
    <>
      <Seo
        title="
        Branding Agency in Bangalore | Hyderabad
        "
        description="Branding agency in Bangalore and Hyderabad. Explore our Branding Page to delve into the story, values, and commitment that make us stand out in the industry. "
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="branding agency in bangalore,
        branding agency in hyderabad,
        top branding agency in India"
        searchengines="ALL"
        canonical="https://www.grank.co.in/branding-agency"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        <div className="branding-banner mobile-none">
          <img
            className="img-fluid w-100"
            src="assest/photos/branding/brand-banner.png"
            alt="G-Rank"
          />
          <div className="branding-banner-new-div">
            <h4>
              <span className="words2"></span>
            </h4>

            <motion.h3
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">Get Started</NavLink>
            </motion.h3>
            <h1>
              Unleash the power of your brand - it's your identity. <br />
              Let's make it truly show-stopping!
            </h1>
            <h1>Your brand is who you are. Let's make it truly impressive.</h1>
            <Breadcrumbs
              className="breadcrumbs"
              aria-label="breadcrumb"
              style={{ paddingTop: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#ccc",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>

              <Typography color="text.primary">Branding</Typography>
            </Breadcrumbs>
          </div>
          <div className="background-color8">
            <motion.div
              initial={{ opacity: 0, scale: 0, y: 100 }}
              whileInView={{ opacity: 1, scale: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="branding-icon8"
            >
              <img
                src="assest/photos/branding/branding-icon8.png "
                alt="grank"
              />
            </motion.div>
          </div>
          <div className="background-color4">
            <motion.div
              initial={{ opacity: 0, scale: 0, y: 100 }}
              whileInView={{ opacity: 1, scale: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="branding-icon4"
            >
              <img
                src="assest/photos/branding/branding-icon4.png "
                alt="grank"
              />
            </motion.div>
          </div>
          <div className="background-color">
            <motion.div
              initial={{ opacity: 0, scale: 0, y: 100 }}
              whileInView={{ opacity: 1, scale: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="branding-icon2"
            >
              <img
                src="assest/photos/branding/branding-icon2.png "
                alt="grank"
              />
            </motion.div>
          </div>
          <motion.div
            initial={{ opacity: 0, scale: 0, y: -400 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="branding-icon3"
          >
            <img src="assest/photos/branding/branding-icon3.png " alt="grank" />
          </motion.div>
          <div className="branding-icon1">
            <img src="assest/photos/branding/branding-icon1.png " alt="grank" />
          </div>
          <div className="branding-icon7">
            <img src="assest/photos/branding/branding-icon7.png " alt="grank" />
          </div>
        </div>
        <div className="branding-banner desktop-none">
          <img
            className="img-fluid w-100"
            src="assest/photos/branding/branding-banner-mobile.png"
            alt="G-Rank"
          />
          <div className="branding-banner-new-div">
            <h4>
              <span className="words2"></span>
            </h4>

            <motion.h3
              href="#"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">Get Started</NavLink>
            </motion.h3>
          </div>

          <div className="background-color8">
            <motion.div
              initial={{ opacity: 0, scale: 0, y: 100 }}
              whileInView={{ opacity: 1, scale: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="branding-icon8"
            >
              <img
                src="assest/photos/branding/branding-icon8.png "
                alt="grank"
              />
            </motion.div>
          </div>
          <div className="background-color4">
            <motion.div
              initial={{ opacity: 0, scale: 0, y: 100 }}
              whileInView={{ opacity: 1, scale: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="branding-icon4"
            >
              <img
                src="assest/photos/branding/branding-icon4.png "
                alt="grank"
              />
            </motion.div>
          </div>
          <div className="background-color">
            <motion.div
              initial={{ opacity: 0, scale: 0, y: 100 }}
              whileInView={{ opacity: 1, scale: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="branding-icon2"
            >
              <img
                src="assest/photos/branding/branding-icon2.png "
                alt="grank"
              />
            </motion.div>
          </div>
          <motion.div
            initial={{ opacity: 0, scale: 0, y: -400 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="branding-icon3"
          >
            <img src="assest/photos/branding/branding-icon3.png " alt="grank" />
          </motion.div>
          <div className="branding-icon1">
            <img src="assest/photos/branding/branding-icon1.png " alt="grank" />
          </div>
          <div className="branding-icon7">
            <img src="assest/photos/branding/branding-icon7.png " alt="grank" />
          </div>
        </div>

        <div className="branding-why-div">
          <div className="container ">
            <div className="row">
              <div className="col-12">
                <div className="brndng-p">
                  <p>
                    Our branding agency has extensive experience in creating
                    impactful brand identities, spanning visual elements such as
                    logos and color palettes to compelling messaging.
                  </p>
                </div>
              </div>
              <motion.div
                className="col-12 col-md-6 col-lg-6 branding-why-text1"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8 }}
              >
                <div className="branding-why-img">
                  <img
                    className="img-fluid "
                    src="assest/photos/branding/branding-why-img.png"
                    alt="G-Rank"
                  />
                </div>
              </motion.div>
              <motion.div
                className="col-12 col-md-6 col-lg-6 branding-why-text"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8 }}
              >
                <h4>Why choose us?</h4>

                <h2>
                  Branding goes way beyond a simple logo or visual identity. It
                  is your brand voice and the story of how your customers feel.
                  Our branding and creative agency team will match you with the
                  perfect branding services to represent your brand.
                </h2>
                <h2>
                  As a top-rated branding agency, we don’t just deliver your
                  brand identity (unless that’s all you want!).. We work with
                  you to fully implement and grow your brand both across the
                  digital universe and in the land of the living.
                </h2>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="branding-process-div">
          <div className="container ">
            <div className="row">
              <div
                className="col-12 col-md-6 col-lg-6 branding-process-text"
                id="order2"
              >
                <motion.h4
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  Our
                </motion.h4>
                <motion.h2
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Process
                </motion.h2>

                <motion.p
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Harnessing creativity, driven by efficient processes, and
                  laser-focused on delivering results. We're not just about the
                  process, we're about the outcome. Your success is our success.
                </motion.p>

                <motion.h3
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  A Personal Process
                </motion.h3>
                <motion.p
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  We're an agency that builds personal relationships with our
                  clients. Rest assured, you'll have a painless, friendly, and
                  fun process.
                </motion.p>

                <motion.h3
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Efficient Project Management
                </motion.h3>
                <motion.p
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Using the SCRUM methodology, we adhere to deadlines, produce
                  high-quality work, and keep you in the loop at all times.
                </motion.p>

                <motion.h3
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  We’re Communicators
                </motion.h3>
                <motion.p
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Our agency prides itself on effective communication. We
                  welcome interaction through various channels, such as email or
                  WhatsApp. No matter the platform, we are committed to
                  providing prompt and reliable support every step of the way.
                </motion.p>
              </div>
              <div
                className="col-12 col-md-6 col-lg-6 branding-process-text1"
                id="order1"
              >
                <div className="branding-process-img">
                  <motion.img
                    className="img-fluid "
                    src="assest/photos/branding/Process-img.png"
                    alt="G-Rank"
                    title="grank"
                    initial={{ opacity: 0, scale: 0, y: 100 }}
                    whileInView={{ opacity: 1, scale: 1, y: 0 }}
                    transition={{ duration: 1 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* action yellow start*/}
        <div className="action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch.
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}

        <div className="branding-service-div">
          <div className="container-fluid">
            <div className="row">
              <motion.div
                className="col-12 branding-service-text2"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                <h4>Our</h4>
                <h2>Branding Services</h2>
              </motion.div>

              <div className="col-12 branding-service-text1">
                <motion.div
                  className="branding-service1"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <div className="flip-card" tabIndex="0">
                    <div className="flip-card-inner">
                      <div className="flip-card-front3">
                        <img
                          src="assest/photos/branding/service-img1.png"
                          alt="G-Rank"
                        />
                        <h4>
                          Logo <br />
                          Design
                        </h4>
                        {/* <h3>More</h3> */}
                      </div>
                      <div className="flip-card-back">
                        <h3>
                          Our team specializes in crafting fully customized logo
                          sets that are inspiring and unforgettable. We ensure
                          that every logo we create captures the essence of your
                          brand's values and vision.
                          <NavLink to="">More</NavLink>
                        </h3>
                      </div>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  className="branding-service1"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  <div className="flip-card" tabIndex="0">
                    <div className="flip-card-inner">
                      <div className="flip-card-front2">
                        <img
                          src="assest/photos/branding/service-img2.png"
                          alt="G-Rank"
                        />
                        <h4>
                          Comprehensive <br />
                          Brand Guide
                        </h4>
                        {/* <h3>More</h3> */}
                      </div>
                      <div className="flip-card-back">
                        <h3>
                          We provide a comprehensive brand guide to maintain
                          consistency and clarity across all your brand
                          materials. This detailed guide covers everything from
                          using your brand fonts, logos, and wordmarks to
                          guidelines for various platforms
                        </h3>
                      </div>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  className="branding-service1"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <div className="flip-card" tabIndex="0">
                    <div className="flip-card-inner">
                      <div className="flip-card-front3">
                        <img
                          src="assest/photos/branding/service-img3.png"
                          alt="G-Rank"
                        />
                        <h4>Typography</h4>
                        {/* <h3>More</h3> */}
                      </div>
                      <div className="flip-card-back">
                        <h3>
                          Selecting the perfect font is crucial in conveying the
                          desired feel of your brand while ensuring readability
                          and accessibility. Our experts assist you in choosing
                          a carefully curated collection of fonts that best
                          represent your brand.
                        </h3>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        {/* Quick Link section start */}
        {/* FAQs section start*/}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <motion.h2
                  className="slider-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  FAQs & Support
                </motion.h2>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => setOpen1(!open1)}
                        >
                          What is branding, and why is it essential for
                          businesses ?
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Branding creates a unique and recognizable product,
                          service, or company identity. The significance of
                          branding for businesses lies in its ability to set
                          them apart from competitors, foster customer loyalty,
                          enhance recognition and trust, and shape consumer
                          choices.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={() => setOpen2(!open2)}
                        >
                          How does G-Rank approach brand strategy and
                          development?
                          {/* <span>
                            {open2 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span> */}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          G-Rank approaches brand strategy and development by
                          conducting comprehensive research, collaborating
                          closely with clients, and crafting compelling
                          narratives that resonate with their target audience.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => setOpen3(!open3)}
                        >
                          Can G-Rank help with rebranding an existing company or
                          product?
                          {/* <span>
                            {open3 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span> */}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Yes, G-Rank excels in rebranding projects by
                          leveraging our expertise in identifying market trends
                          and consumer preferences and creating fresh brand
                          identities that resonate with the modern audience.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse4"
                          aria-expanded="false"
                          aria-controls="collapse4"
                          onClick={() => setOpen4(!open4)}
                        >
                          How do you build a strong brand identity for your
                          business?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse4"
                      className="collapse"
                      aria-labelledby="heading4"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Brand identity is a crucial aspect of a business,
                          defining a company's values, personality, and promise
                          to customers. It sets the company apart and creates a
                          lasting impression. Tailoring it using market
                          research, surveys, feedback, and analytics is
                          essential.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <h2 className="mb-0  panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse5"
                          aria-expanded="false"
                          aria-controls="collapse5"
                          onClick={() => setOpen5(!open5)}
                        >
                          How can I start with G-Rank to elevate my brand's
                          presence and impact?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse5"
                      className="collapse"
                      aria-labelledby="heading5"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Getting started with G-Rank is simple - just reach out
                          to our team, and we'll guide you through our proven
                          process to effectively elevate your brand's presence
                          and impact.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQs section end*/}
        <section>
          <div className="container-fluid">
            <div>
              <motion.h2
                className="branding-quick-link"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Quick Links
                <p className="d-block"> For You</p>
              </motion.h2>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}

        <img
          className="img-fluid w-100"
          src="assest/photos/branding/branding-footer-img.png"
          alt="G-Rank"
        />
      </section>
    </>
  );
};

export default Branding;
