import React, { useState } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { NavLink } from "react-router-dom";
import Seo from "../seo/Seo";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";

const DomainTransfer = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // FAQs section start
  // useState section start--------------------------------------
  //const [isShowMore, setIsShowMore] = useState(false);

  // acoordian button
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  //  useState section end----------------------------------------
  // FAQs section end

  const schema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "G-Rank",
        item: "https://www.grank.co.in/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Doman",
        item: "https://www.grank.co.in/domain",
      },
      {
        "@type": "ListItem",
        position: 3,
        name: "Domain Transfer",
        item: "https://www.grank.co.in/domain-transfer",
      },
    ],
  };

  return (
    <>
      <Seo
        title="Domain Transfer Services in Bangalore | Hyderabad"
        description="Domain Transfer services in bangalore and hyderbad. Explore how we effortlessly move your web domains to a more reliable, efficient platform."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="best domain transfer services in India "
        searchengines="ALL"
        canonical="https://www.grank.co.in/domain-transfer"
        schema={schema}
      ></Seo>

      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/domain/domain-transfer-banner.png"
            alt="G-Rank"
            title="grank"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/domain/domain-transfer-banner-mobile.png"
            alt="G-Rank"
            title="grank"
          />

          <div className="domain-banner-div">
            <motion.h1
              style={{ color: "#ffff" }}
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Domain Transfer
            </motion.h1>
            <motion.h5
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Domain Transfer Made Effortless!
            </motion.h5>
            <Breadcrumbs
              className=" breadcrumbs"
              aria-label="breadcrumb"
              style={{ float: "right", paddingTop: "140px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/domain"
              >
                <p>Domain</p>
              </NavLink>

              <Typography color="#fff">Domain Transfer</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}
        {/* yellow section start */}

        <section className="yellow-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h4 className="yellow-section-heading">
                  Choose From The Most Popular
                  <br /> Domain Extensions
                </h4>
              </div>
              <div className="col-12 col-md-4 col-lg-4 ">
                <div className="yellow-transfer-section-div">
                  <img
                    className="img-fluid "
                    src="assest/photos/domain/domain-icon3.png"
                    alt="G-Rank"
                    title="domain"
                  />
                  <h4>
                    Advanced <br />
                    Domain Management
                  </h4>
                  <p>
                    Effortlessly manage numerous domains and websites using a
                    single control panel.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 ">
                <div className="yellow-transfer-section-div">
                  <img
                    className="img-fluid "
                    src="assest/photos/domain/domain-icon2.png"
                    alt="G-Rank"
                    title="domain"
                  />
                  <h4>
                    24/7 Expert Chat
                    <br />
                    Support
                  </h4>
                  <p>
                    Count on us to swiftly address issues and have your websites
                    operational immediately.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 ">
                <div className="yellow-transfer-section-div">
                  <img
                    className="img-fluid"
                    src="assest/photos/domain/domain-icon1.png"
                    alt="G-Rank"
                    title="domain"
                  />
                  <h4>
                    Optimized Website
                    <br />
                    Speed
                  </h4>
                  <p>
                    Treat your visitors to an exceptional experience with
                    quick-loading, high-performing websites.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* yellow section end */}
        {/* domain plan start */}
        <section className="domain-plan-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <motion.h4
                  className="domain-slider-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Give Your Domain A Boost
                </motion.h4>
              </div>
              <div className="col-12 col-md-3 col-lg-3 ">
                <div className="domain-name-div">
                  <h4>
                    Use the G-Rank Domain transfer tool and enter a domain
                  </h4>
                  <p>
                    Enter your website name to initiate the Domain transfer
                    process.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3 ">
                <div className="domain-name-div">
                  <h4>Complete the payment to transfer a domain</h4>
                  <p>
                    Pay the Domain transfer charges, and you will be redirected
                    to our member's area.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3">
                <div className="domain-name-div">
                  <h4>Enter the EPP code or authorization code.</h4>
                  <p>
                    As a Domain owner, you can obtain the auth code from your
                    current registrar.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3 ">
                <div className="domain-name-div">
                  <h4>Confirm your G-Rank Domain transfer.</h4>
                  <p>
                    You will receive a confirmation email from your previous
                    registrar to authorize the Domain transfer to G-Rank.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* domain plan end*/}

        {/* FAQs section start*/}
        <section className="FAQs-heading-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h4>Frequently Asked Questions</h4>
              </div>
            </div>
          </div>
        </section>
        <section className="domain-extention-FAQs-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => setOpen1(!open1)}
                        >
                          How do I initiate a domain transfer to G Rank?
                          <span>
                            {open1 ? (
                              <AddIcon className="accordion-arrow" />
                            ) : (
                              <RemoveIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          To transfer your domain to G Rank, start by unlocking
                          the domain at your current registrar. Then, follow our
                          straightforward transfer process, which typically
                          includes an authorisation code.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={() => setOpen2(!open2)}
                        >
                          Can I transfer any type of domain to G Rank?
                          <span>
                            {open2 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse show"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          G Rank supports the transfer of various domain
                          extensions. Check our domain transfer page for the
                          list of supported domains, and feel free to contact
                          our support team for specific inquiries.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading3">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse3"
                          aria-expanded="false"
                          aria-controls="collapse3"
                          onClick={() => setOpen2(!open2)}
                        >
                          What information do I need for a domain transfer?
                          <span>
                            {open2 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse3"
                      className="collapse show"
                      aria-labelledby="heading3"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          You'll typically need the domain name, the EPP code
                          (authorization code) from your current registrar, and
                          contact information associated with the domain's
                          administrative contact.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse4"
                          aria-expanded="false"
                          aria-controls="collapse4"
                          onClick={() => setOpen4(!open4)}
                        >
                          Are there additional fees for transferring a domain to
                          G Rank?
                          <span>
                            {open4 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse4"
                      className="collapse show"
                      aria-labelledby="heading4"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          G Rank maintains transparency with no hidden fees. The
                          cost displayed during the transfer process covers the
                          domain transfer and any associated services.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse5"
                          aria-expanded="false"
                          aria-controls="collapse5"
                          onClick={() => setOpen5(!open5)}
                        >
                          How long does it take to complete a domain transfer to
                          G Rank?
                          <span>
                            {open5 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse5"
                      className="collapse show"
                      aria-labelledby="heading5"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          The timeframe for domain transfers varies, but G Rank
                          strives to make it as quick as possible. Typically,
                          transfers take 5 to 7 days, during which your website
                          remains operational.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQs section end*/}

        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h4
                className="web-develop-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Quick Links For You
              </motion.h4>
            </div>
            <div>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        {/* Black-section-div start*/}
        <section>
          <div className="footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/domain/white-and-blue-back.png"
              alt="G-Rank"
              title="domain"
            />
            <div className="footer-div-text">
              <motion.p
                initial={{ opacity: 0, scale: 0, y: 100 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                Want to Elevate Your Brand?
                <br /> Get in Touch.
              </motion.p>
              <NavLink to="">
                <motion.p
                  className="footer-div-buttom"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                  onClick={handleOpen}
                >
                  Contact Us <ArrowRightAltIcon />
                </motion.p>
              </NavLink>
            </div>
          </div>
        </section>
        {/* Black-section-div end*/}
      </section>
    </>
  );
};

export default DomainTransfer;
