import React from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import Seo from "../seo/Seo";
import { NavLink } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";
const PaidMarketing = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings10 = {
    className: "center-slider",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 5,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const schema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "G-Rank",
        item: "https://www.grank.co.in/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "social media agency",
        item: "https://www.grank.co.in/social-media-agency",
      },
      {
        "@type": "ListItem",
        position: 3,
        name: "Paid Marketing Agency",
        item: "https://www.grank.co.in/paid-marketing-agency",
      },
    ],
  };

  return (
    <>
      <Seo
        title="Paid Marketing Agency In India - G-Rank"
        description="Elevate your brand with G-Rank, India's premier paid advertising agency. Unlock growth through targeted strategies and expert insights."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="facebook advertising agency in bangalore,
        instagram ads agency,
        facebook ads agency,
        instagram marketing agency,
        linkedin marketing agency in hyderbad,
        top paid social media agency in India"
        searchengines="ALL"
        canonical="https://www.grank.co.in/paid-marketing-agency"
        schema={schema}
      ></Seo>

      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/social-media/social-media-banner.png"
            alt="G-Rank"
            title="social media"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/social-media/social-media-banner2.png"
            alt="G-Rank"
            title="social media"
          />

          <motion.img
            className="social-banner-img mobile-none"
            src="assest/photos/social-media/social-media-img3.png"
            alt="G-Rank"
            title="social media"
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          />

          <div className="social-banner-div">
            <h1 className="waviy ">
              <span>Paid Marketing Agency</span>
            </h1>

            <h2>Stand Out, Get Noticed​​</h2>

            <motion.h3
              href="#"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">LEARN MORE</NavLink>
            </motion.h3>
            <Breadcrumbs
              className=" breadcrumbs"
              aria-label="breadcrumb"
              style={{ padding: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/social-media-agency"
              >
                <p>Social Media</p>
              </NavLink>

              <Typography color="#fff">Paid Marketing Agency</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}

        {/* text section start */}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <motion.h4
                  className="social-header"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  G-Rank’s Strategy As A Paid Advertising Agency
                </motion.h4>
              </div>
              <div className="col-12">
                <motion.h2
                  className="creative-header2"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                >
                  G-Rank is redefining the success of your businesses through
                  our innovative approach as a paid ads marketing agency. We
                  focus on understanding your unique objectives and audience,
                  allowing us to create campaigns that truly connect.
                  <br />
                  By blending creativity with compelling storytelling, we ensure
                  your brand captures attention in a crowded marketplace. Our
                  team continuously monitors and refines strategies to maximise
                  ROI. With a collaborative partnership, G-Rank empowers you to
                  reach new heights, driving growth with effective advertising
                  that captivates and converts.
                </motion.h2>
              </div>
            </div>
          </div>
        </section>
        {/* text section start */}
        {/* blue grey start */}
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-5 social-question-div">
                <img
                  className="img-fluid  shakex"
                  src="assest/photos/social-media/social-sub-question-img.png"
                  alt="G-Rank"
                  title="social media"
                />
              </div>
              <div className="col-12 col-md-7 col-lg-7 social-question-text">
                <motion.h4
                  className="social-question-header"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Why Our Paid Marketing Company is Best?
                </motion.h4>

                <motion.h2
                  className="social-question-header2"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                >
                  G-Rank is the best-paid social media agency because we take a
                  precise and strategic approach to delivering exceptional
                  results for your brand. Our process optimises your paid media
                  campaigns and drives measurable ROI. Our team thoroughly
                  analyses your target audience, industry landscape, and
                  competitors to gain insights into your market. It helps us
                  create a tailored strategy to maximise your brand's visibility
                  and engagement.
                  <br />
                  <br />
                  Our experienced team also crafts a comprehensive media plan
                  that aligns with your goals and budget. We leverage our
                  extensive network of advertising platforms to ensure your ads
                  reach the right audience at the right time, maximising your
                  reach and impact. Throughout the campaign, we continuously
                  monitor and optimise your ads, making data-driven decisions to
                  improve performance and achieve higher conversions. Our
                  agency's expertise ensures your advertising campaigns are
                  executed flawlessly, delivering exceptional results and
                  helping your brand stay ahead in a competitive market.
                </motion.h2>
              </div>
            </div>
          </div>
        </section>

        {/* blue grey end*/}
        {/* action yellow start*/}
        <div className="action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch.
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}
        {/* icon slider start*/}
        <div className="container-fluid slider-icon-div">
          <div className="row ">
            <div className="col-12">
              <motion.h4
                className="social-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Our Paid Social Media Services​
              </motion.h4>
            </div>
            <div className="col-12">
              <motion.h2
                className="social-header2"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.2 }}
              >
                We provide a wide range of services related to Paid Media.
                <br />
                Our services include:
              </motion.h2>
            </div>
            <div className="col-12">
              <Slider {...settings10}>
                <div className="icon-slider">
                  <img
                    className="img-fluid  "
                    src="assest/photos/social-media/facebook.png"
                    alt="G-Rank"
                    title="social media"
                  />
                  <h5>Facebook</h5>
                </div>
                <div className="icon-slider">
                  <img
                    className="img-fluid  "
                    src="assest/photos/social-media/linkten.png"
                    alt="G-Rank"
                    title="social media"
                  />
                  <h5>Linkedin</h5>
                </div>
                <div className="icon-slider">
                  <img
                    className="img-fluid "
                    src="assest/photos/social-media/pexel.png"
                    alt="G-Rank"
                    title="social media"
                  />
                  <h5>Pinterest</h5>
                </div>
                <div className="icon-slider">
                  <img
                    className="img-fluid  "
                    src="assest/photos/social-media/q-icon.png"
                    alt="G-Rank"
                    title="social media"
                  />
                  <h5>Quora</h5>
                </div>
                <div className="icon-slider">
                  <img
                    className="img-fluid "
                    src="assest/photos/social-media/insta.png"
                    alt="G-Rank"
                    title="social media"
                  />
                  <h5>Instagram</h5>
                </div>
                <div className="icon-slider">
                  <img
                    className="img-fluid "
                    src="assest/photos/social-media/twitter.png"
                    alt="G-Rank"
                    title="social media"
                  />
                  <h5>Twitter</h5>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        {/* icon slider end*/}
        {/* what we do start */}
        <div className="container-fluid social-media-what">
          <div className="row">
            <div className="col-12 col-md-7 col-lg-7 " id="order2">
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                className=" branding-consulting-what-heading"
              >
                <h4>What</h4>
                <h2>we Do</h2>
              </motion.div>
              <div className="branding-consulting-what-text">
                <p>Develop strategies for paid social media campaigns</p>
                <p>Define precise demographics for targeted ads</p>
                <p>Design compelling and optimized ad creatives</p>
                <p>Determine budget allocation for different campaigns</p>
                <p>Choose suitable social media platforms for ads</p>
                <p>Select placements to reach the intended audience</p>
                <p>Optimize bids for maximum return on investment (ROI)</p>
                <p>Write compelling and concise captions for posts</p>
                <p>Set up and monitor conversion tracking goals</p>
                <p>Develop retargeting strategies for past visitors</p>
                <p>Track metrics to assess campaign performance</p>
                <p>Adjust campaigns based on performance insights</p>
                <p>Stay updated on current social media trends</p>
                <p>Stay updated on platform changes and trends</p>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-5 " id="order1">
              <motion.img
                initial={{ opacity: 0, scale: 0, y: 200 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="img-fluid branding-consulting-process-img"
                src="assest/photos/branding/what-we-do-img-yellow.png"
                alt="G-Rank"
                title="social media"
              />
            </div>
          </div>
        </div>
        {/* what we do end */}
        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h4
                className="creative-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Quick Links <span>For You</span>
              </motion.h4>
            </div>
            <div>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        <img
          className="img-fluid w-100"
          src="assest/photos/branding/branding-footer-img.png"
          alt="G-Rank"
          title="social media"
        />
      </section>
    </>
  );
};

export default PaidMarketing;
