import React, { useState, useEffect } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Seo from "../seo/Seo";
import Counter from "./circular-progress-bar/Counter";
import { NavLink } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CustomerContext from "../blog-folder/CustomerContext";
const Tech = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settings2 = {
    infinite: true,

    autoplay: true,

    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Slider section start-----------------------------------------
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="slider-custome-button-next" onClick={onClick}>
        <KeyboardArrowRightIcon className="forword-arrow" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="slider-custome-button-prev" onClick={onClick}>
        <KeyboardArrowLeftIcon className="backword-arrow" />
      </div>
    );
  }

  // -----------------------------------------------------------------
  // acoordian button
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  const schema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "G-Rank",
        item: "https://www.grank.co.in/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "B2B Digital Marketing Agency",
        item: "https://www.grank.co.in/b2b-digital-marketing-agency",
      },
      {
        "@type": "ListItem",
        position: 3,
        name: "Tech Digital Marketing Agency",
        item: "https://www.grank.co.in/tech-digital-marketing-agency",
      },
    ],
  };

  return (
    <>
      <Seo
        title="Digital Marketing Agency for Tech Industries| Bangalore | Hyderabad"
        description="Digital marketing agency for tech industry in bangalore and hyderabad. Empower your tech brand with our Digital Marketing expertise. Explore how we navigate the ever-evolving tech landscape, boost online visibility"
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="best digital marketing agency for tech industries in India,
        digital marketing agency for tech industries in bangalore,
        digital marketing agency for tech industries in hyderabad"
        searchengines="ALL"
        canonical="https://www.grank.co.in/tech-digital-marketing-agency"
        schema={schema}
      ></Seo>

      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/b2b/b2b-banner3.png"
            alt="G-Rank"
            title="Tech"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/b2b/b2b-banner3-mobile.png"
            alt="G-Rank"
            title="Tech"
          />

          <div className="b2b-banner-div">
            <motion.h2
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Tech
            </motion.h2>

            <h1 className="b2b-1">Tech Digital Marketing Agency</h1>
            <motion.h3
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">Get a Quote</NavLink>
            </motion.h3>
            <Breadcrumbs
              className="breadcrumbs"
              aria-label="breadcrumb"
              style={{ float: "right" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#000",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#000",
                })}
                to="/b2b-digital-marketing-agency"
              >
                <p>B2B</p>
              </NavLink>

              <Typography color="#000">Tech</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}
        {/* tech-second start */}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-5 align-self-center">
                <h2 className="b2b-message-h2 b2b-pt">
                  Sculpting <br />
                  the Tech
                </h2>
                <h4 className="b2b-message-div">
                  <span></span>
                  <div className="b2b-message">
                    <div className="word1">Landscape</div>
                    <div className="word2">Space</div>
                    <div className="word3">Setting</div>
                  </div>
                </h4>
              </div>
              <div className="col-12 col-md-12 col-lg-7">
                <p className="b2b-message-p b2b-pt">
                  A top digital marketing agency with over 300 satisfied clients
                  and 13 years of experience, G-Rank Digital Marketing can help
                  your tech industry grow and increase brand value.
                  <br />
                  <br />
                  But what truly sets us apart is our personalised, hands-on
                  service. We believe in being your trusted partner, guiding you
                  every step of the way with patience and care. Your success is
                  our top priority, and we will work towards adding value to
                  your marketing objective.
                  <br />
                  <br />
                  Our friendly team fosters meaningful relationships, creating
                  an environment where collaboration and trust thrive. Contact
                  G-Rank Digital Marketing today, and let's embark on this
                  transformative journey together!
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* tech-second end */}
        <motion.h2
          className="slider-heading"
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6 }}
        >
          Statistics
        </motion.h2>
        <section className="circular-percentage-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-3 col-lg-3">
                <Counter number={105} title="client" />
              </div>
              <div className="col-12 col-md-3 col-lg-3">
                <Counter number={90} title="Projects" />
              </div>
              <div className="col-12 col-md-3 col-lg-3">
                <Counter number={75} title="Leads" />
              </div>
              <div className="col-12 col-md-3 col-lg-3">
                <Counter number={85} title="Conversations" />
              </div>
            </div>
          </div>
        </section>

        {/* tech-second end */}

        {/* Quick Link section start */}
        <section className="margin-top-bott">
          <div className="container-fluid">
            <div>
              <motion.h2
                className="slider-heading"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Tech Digital Marketing Services
              </motion.h2>
              <Slider {...settings3}>
                <div className="b2b-slider-div2">
                  <h4 className="b2b-slider-img2">Branding</h4>
                </div>
                <div className="b2b-slider-div2">
                  <h4 className="b2b-slider-img2">
                    Creative & <p className="d-block"> Content</p>
                  </h4>
                </div>
                <div className="b2b-slider-div2">
                  <h4 className="b2b-slider-img2">
                    Organic <p className="d-block"> marketing</p>
                  </h4>
                </div>

                <div className="b2b-slider-div2">
                  <h4 className="b2b-slider-img2">
                    Performance <p className="d-block"> marketing</p>
                  </h4>
                </div>
                <div className="b2b-slider-div2">
                  <h4 className="b2b-slider-img2">Social Media</h4>
                </div>
                <div className="b2b-slider-div2">
                  <h4 className="b2b-slider-img2">
                    Web <p className="d-block"> Development</p>
                  </h4>
                </div>
                <div className="b2b-slider-div2">
                  <h4 className="b2b-slider-img2">Domain</h4>
                </div>
                <div className="b2b-slider-div2">
                  <h4 className="b2b-slider-img2">
                    Web <p className="d-block"> Hosting</p>{" "}
                  </h4>
                </div>
                <div className="b2b-slider-div2">
                  <h4 className="b2b-slider-img2">Security</h4>
                </div>
                <div className="b2b-slider-div2">
                  <h4 className="b2b-slider-img2">Email</h4>
                </div>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}

        {/* Testinomial section start */}
        <section className="FAQs-heading-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h4>Testimonials</h4>
              </div>
            </div>
          </div>
        </section>
        <section className="b2b-testimonial-section-margin">
          <div className="container">
            <h4 className="b2b-testinomial-heading">Google Reviews</h4>
            <Slider {...settings2}>
              <div className="b2b-testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 " id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      Provided best services and we have got minimum 10-15
                      Genuine Enquiries from G-Rank for every month also results
                      in booking of orders & this is the best platform for
                      marketing purposes like Newspapers,Social media
                      Advertisements,etc. My suggestion is to take the service
                      from G-Rank for better company profile development and
                      orders booking.
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img "
                        src="assest/photos/female-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name">
                        <h3>Tech Mech Cranes</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 " id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      Six-years journey with G-Rank has elevated our business
                      and consistent support from the team,driven quality leads
                      to our industry. We thank you for providing us with value
                      services. Best wishes from Ameenji Rubber Pvt Ltd ...
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6 " id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img "
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name2">
                        <h3>Ameenji Rubber Private Limited</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6 " id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      G-Rank has helped our business grow in the last couple of
                      years. The best part I like about them is that they care
                      for my business and they have an understanding of the
                      business side . I've had a great experience working with
                      their team
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name3">
                        <h3>Kumari Gaddalay</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6 " id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      Associated with G-Rank for the last two years for my two
                      institutions. This team is a team of action and delivers
                      what it promises. Very happy to be associated with them.
                      Trustworthy company.
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name4">
                        <h3>Shantiniketan International School</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6 " id="order2">
                    <img
                      className="testimonial-comma"
                      alt="G-Rank"
                      title="b2b"
                      src="assest/photos/comma-new.png"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      G-Rank has experienced professionals who can help you in
                      the digital way of promoting your website in all the
                      possible ways. I would recommend G-Rank if you are looking
                      for your business/product promotions
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name5">
                        <h3>Advance Aqua Biotech Private Limited</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      A comprehensive digital strategy by G-Rank and a
                      continuous service provided by their team as per our
                      requirement has not only improved our website quality but,
                      also provided the quality leads to our school.
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name6">
                        <h3> SNVP School</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6 " id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      An excellent team, good approach, aggressive in
                      promotion,challenging in nature. Satisfied with their
                      performance. Total team is productive.Our appreciation to
                      G rank.
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name7">
                        <h3>Ace Constructions</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      we have been associated with digital marketing services
                      for 3 months and the results are appreciable. We are happy
                      with the services provided and looking to extend further.
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name8">
                        <h3>Esennar Transformers</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      Good Service from the G-Rank. We recently signed for 1 yr
                      contract with this team. The service and web-design is
                      good !!
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name9">
                        <h3>Candeur Landmark</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      We have been associated with G-Rank for the last six
                      years. All our social media, digital marketing, website
                      maintenance, and lead generation activities are well taken
                      care of by the highly professional team at G-Rank. We are
                      happy with the quick response and resolution extended by
                      Mr. Sandeep. Expecting the same in our future projects
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name10">
                        <h3>Ecolife Developers</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      Best solution for Digital marketing services and also good
                      support and service from the team especially anjali- will
                      respond quickly and address the issue properly
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name11">
                        <h3>Bharadwaj Packaging</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      G-Rank is very professional and prompt in resolution of
                      all issues. They have been handling our account for many
                      years now and we are very happy with their service.
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name12">
                        <h3>RR Stone Products</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      They have been very cooperative and support all through
                      our discussions . The service is very good and prompt.
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name13">
                        <h3> TSG Audio Innovations</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      We have been working with G-Rank for the last 18months and
                      getting relevant enquiries. Especially their remarketing
                      banner on the webpage is very effective. Very nice staff.
                      Good to be a partner with G-rank for digital marketing.
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name14">
                        <h3>Dirak</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      For the last 2+ years we have been associated with G-Rank
                      for Website, Gsuite, and promotions.Recently we launched
                      our completely revamped website and couldn't be any
                      happier with the way the site turned out. Our old website
                      with outdated graphics, fonts, etc., was transformed into
                      a modern and incredibly attractive site.We are also quite
                      happy with their service visits, coordination, and
                      follow-ups and they are quick enough in responding to our
                      concerns.
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name15">
                        <h3>Ravoos Laboratories</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      we have been associated with the Grank for the last two
                      and half year , their support staff is extremely helpful
                      would recommend this company to anybody
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name16">
                        <h3>Rajapushpa Properties</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-slider-div">
                <div className="row">
                  <div className="col-12 col-md-6 col-md-6" id="order2">
                    <img
                      className="testimonial-comma"
                      src="assest/photos/comma-new.png"
                      alt="G-Rank"
                      title="b2b"
                    />
                    <motion.div
                      className="b2b-testimonial-slider-text"
                      initial={{ opacity: 0, scale: 0, y: 100 }}
                      whileInView={{ opacity: 1, scale: 1, y: 0 }}
                      transition={{ duration: 0.6 }}
                    >
                      We have been associated with G Rank for the last 1 year
                      and we are happy with the services and the enquiries
                      generated. We look forward to the same support in the
                      coming year.
                    </motion.div>
                  </div>
                  <div className="col-12 col-md-6 col-md-6" id="order1">
                    <div className="testimg">
                      <motion.img
                        initial={{ opacity: 0, scale: 0, x: 100 }}
                        whileInView={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{ duration: 0.6 }}
                        className="img-fluid testinomial-section-img animate__bounceIn"
                        src="assest/photos/male-avatar.png"
                        alt="G-Rank"
                        title="b2b"
                      />
                      <div className="b2b-testinomial-section-name17">
                        <h3>Primex Plastics</h3>
                        <h2>Business Owner</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section>
        {/* testinomial section end */}
        {/* FAQs section start*/}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <motion.h2
                  className="slider-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  FAQs & Support
                </motion.h2>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => setOpen1(!open1)}
                        >
                          How can digital marketing benefit technology companies
                          or startups?
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Digital marketing helps technology companies reach a
                          global audience, generate leads, and enhance brand
                          visibility. It enables targeted advertising, content
                          marketing, and social media campaigns to engage with
                          potential customers. With the penetration of the
                          internet to every nook and corner of the country and
                          as startups it is very important for you to create
                          awareness. In the current scenario, Digital Marketing
                          can give you the maximum reach to a targeted audience,
                          keeping your ROI in mind.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={() => setOpen2(!open2)}
                        >
                          What are some helpful digital marketing strategies for
                          the tech industry?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Branding and Awareness campaigns and strategic
                          lead-generation activities ensure an effective digital
                          marketing campaign for the tech and startup industry.
                          Selecting the correct platform to ensure you reach
                          your relevant audience properly is very important.
                          Factors such as what time of the day, what day of the
                          week, A/B Testing and many more ensure a successful
                          digital marketing activity for your startup.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => setOpen3(!open3)}
                        >
                          Can digital marketing help generate leads for
                          technology companies?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Yes, digital marketing strategies like SEO, PPC, and
                          targeted advertising can drive qualified leads to the
                          business website. Businesses can effectively capture
                          leads by implementing lead capture forms,
                          call-to-action buttons, and tracking tools. We can
                          also effectively engage and nurture these leads
                          through their journey. With the extensive internet
                          reach across the nation, we can rest assured that your
                          audience is definitely there online. We ensure your
                          brand is visible to them at the right time and place.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse4"
                          aria-expanded="false"
                          aria-controls="collapse4"
                          onClick={() => setOpen4(!open4)}
                        >
                          How does social media marketing play a role in tech
                          companies?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse4"
                      className="collapse"
                      aria-labelledby="heading4"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Social media advertising increases brand visibility
                          and lead generation by targeting certain demographics
                          and interests based on client requirements. Social
                          media is normally a platform where information and
                          content can be made viral and reach and engage a wide
                          range of audiences. It is also a very effective
                          engagement tool.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse5"
                          aria-expanded="false"
                          aria-controls="collapse5"
                          onClick={() => setOpen5(!open5)}
                        >
                          How can G Rank Digital Marketing help technology
                          companies with their online presence?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse5"
                      className="collapse"
                      aria-labelledby="heading5"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          G Rank Digital Marketing specialises in developing
                          custom digital marketing strategies tailored to the
                          tech industry. They offer services like SEO, PPC,
                          content marketing, social media management, and
                          website development to improve online visibility and
                          drive business growth. We understand your marketing
                          needs and customise a digital marketing plan that can
                          suit your business needs.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQs section end*/}
        {/* our client start */}
        <section>
          <div className="col-12">
            <motion.h2
              className="slider-heading"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Our Clientele
            </motion.h2>
          </div>
          <div className="container-fluid">
            <div>
              <Slider {...settings3}>
                <NavLink to="/eco-life">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/eco-life.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                  </div>
                </NavLink>
                <NavLink to="/cms">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/cms.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                  </div>
                </NavLink>
                <NavLink to="/jayabheri">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/jaya.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                  </div>
                </NavLink>
                <NavLink to="/performax">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/performax.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                  </div>
                </NavLink>
                <NavLink to="/dirak">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/dirak.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                  </div>
                </NavLink>
                <NavLink to="/signature">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/signature.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                  </div>
                </NavLink>
                <NavLink to="/shodhana">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/shodhana.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                  </div>
                </NavLink>
                <NavLink to="/ramky">
                  <div className="slider-div">
                    <img
                      className="slider-img-absolute1"
                      src="assest/photos/top-slider-img.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                    <img
                      className="slider-img-absolute-9"
                      src="assest/photos/client-icon/ramky.png"
                      alt="G-Rank"
                      title="Tech"
                    />
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* our client end */}

        {/* Black-section-div start*/}
        <section>
          <div className="footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/banner-footer32.png"
              alt="G-Rank"
              title="Tech"
            />
            <div className="footer-div-text">
              <motion.p
                initial={{ opacity: 0, scale: 0, y: 100 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                Want to Grow your
                <br /> Tech Business?
              </motion.p>
              <NavLink to="">
                <motion.p
                  className="footer-div-buttom"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                  onClick={handleOpen}
                >
                  Contact Us <ArrowRightAltIcon />
                </motion.p>
              </NavLink>
            </div>
          </div>
        </section>
        {/* Black-section-div end*/}
      </section>
    </>
  );
};

export default Tech;
